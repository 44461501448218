import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dea6a90c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "body" }
const _hoisted_2 = { class: "tail" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["item", { 'item-divider': _ctx.hasDivider }])
  }, [
    _renderSlot(_ctx.$slots, "head", {}, undefined, true),
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "body", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "tail", {}, undefined, true)
    ])
  ], 2))
}