import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-160cbaad"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item-value" }
const _hoisted_2 = { class: "text-left" }
const _hoisted_3 = { class: "item-value" }
const _hoisted_4 = { class: "text-center" }
const _hoisted_5 = { class: "item-value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tag = _resolveComponent("Tag")!
  const _component_ItemInList = _resolveComponent("ItemInList")!

  return (_openBlock(), _createBlock(_component_ItemInList, null, {
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, [
          _renderSlot(_ctx.$slots, "value-primary", {}, undefined, true)
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, [
          _renderSlot(_ctx.$slots, "value-secondary", {}, undefined, true)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_Tag, null, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "value-tag", {}, undefined, true)
          ]),
          _: 3
        })
      ])
    ]),
    _: 3
  }))
}