
import { defineComponent } from "vue";

export default defineComponent({
  name: "ItemInList",
  props: {
    hasDivider: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {};
  },
});
