
import { defineComponent, PropType, ref } from "vue";
import ItemInList from "@/components/atomics/list-item/ItemInList.vue";
import Tag from "@/components/atomics/Tag.vue";

export default defineComponent({
  name: "ItemInListInfo",
  components: {
    ItemInList,
    Tag,
  },
});
