import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e925b0dc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-header" }
const _hoisted_2 = { class: "section-header" }
const _hoisted_3 = { class: "gmo-title text-left" }
const _hoisted_4 = { class: "gmo-title text-center" }
const _hoisted_5 = { class: "gmo-title text-center" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { key: 1 }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BreadScrum = _resolveComponent("BreadScrum")!
  const _component_ItemInListInfo = _resolveComponent("ItemInListInfo")!
  const _component_AfterLoginPage = _resolveComponent("AfterLoginPage")!

  return (_openBlock(), _createBlock(_component_AfterLoginPage, { onScrolledToBottom: _ctx.handleLoad }, {
    "top-part": _withCtx(() => [
      _createVNode(_component_BreadScrum, { paths: _ctx.databreadScrumLinks }, null, 8, ["paths"])
    ]),
    "left-top-part": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("company.gmoDate")), 1),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("company.gmoFee")), 1),
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("company.gmoStatus")), 1)
        ])
      ])
    ]),
    "left-bottom-part": _withCtx(() => [
      (_ctx.gmoList.length == 0)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "content-blank",
            innerHTML: _ctx.$t('company.noItem')
          }, null, 8, _hoisted_6))
        : (_openBlock(), _createElementBlock("div", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.gmoList, (gmo, index) => {
              return (_openBlock(), _createBlock(_component_ItemInListInfo, {
                key: index,
                "has-divider": ""
              }, {
                "value-primary": _withCtx(() => [
                  _createElementVNode("div", {
                    class: "gmo-date",
                    onClick: ($event: any) => (_ctx.onDownloadInvoice(gmo.filePath))
                  }, _toDisplayString(`${_ctx.formatDate(gmo.paymentDate)}`), 9, _hoisted_8)
                ]),
                "value-secondary": _withCtx(() => [
                  _createTextVNode(_toDisplayString(`${_ctx.formatter.format(gmo.amount)}`), 1)
                ]),
                "value-tag": _withCtx(() => [
                  (gmo.paidAt != null)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.$t("company.gmoPaid")), 1))
                    : (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.$t("company.gmoUnpaid")), 1))
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]))
    ]),
    _: 1
  }, 8, ["onScrolledToBottom"]))
}