
import { defineComponent, onMounted, reactive, ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { RepositoryFactory, CompanyRepository, InvoiceRepository } from "@/lib/https";
import { formatDate } from "@/lib/utility/dateTime";
import BreadScrum from "@/components/atomics/BreadScrum.vue";
import useList from "@/lib/compositional-logic/useList";
import ItemInListInfo from "@/components/atomics/list-item/ItemInListInfo.vue";
import AfterLoginPage from "@/views/layouts/AfterLoginAdminPage.vue";

export default defineComponent({
  name: "GMO",
  components: {
    BreadScrum,
    ItemInListInfo,
    AfterLoginPage
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();

    const state = reactive({
      companyName: "",
    }); 

    const companyID = route.params.id;

    const databreadScrumLinks = ref([
        { text: t("company.titleListCompanies"), to: "/company" },
        { text: "", to: "/company/" + companyID },
        { text: t("company.gmoTitle"), to: ""},
    ]);

    const { getCompanySimple } = RepositoryFactory.getRepository<CompanyRepository>(CompanyRepository);

    const getCompanyInfo = async () => {
      const response = await getCompanySimple(companyID.toString());
      if (!response) return;

      databreadScrumLinks.value[1].text = response.name;
    }

    onMounted(getCompanyInfo);

    const { getListOfGMOInvoices } = RepositoryFactory.getRepository<InvoiceRepository>(InvoiceRepository);
    const {
      list: gmoList,
      loadMore,
    } = useList({
      initialLoadList: async ({ pageSize }) =>
        await getListOfGMOInvoices( companyID.toString(), {pageSize} ),
      loadMoreList: async ({ page, pageSize }) =>
        await getListOfGMOInvoices( companyID.toString(), {page, pageSize} ),
    });

    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "JPY",
    });

    const handleLoad = computed(() =>
      loadMore
    );

    return {
      getCompanyInfo,
      databreadScrumLinks,
      gmoList,
      formatter,
      handleLoad,
    };
  },
  methods: {
    formatDate(date: string) {
      return formatDate(date, "YYYY/MM/DD hh:mm");
    },
    onDownloadInvoice(filePath: string) {
      window.open(process.env.VUE_APP_API_CLOUD_URL + filePath);
    },
  }
  
});
